// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-enterprise-js": () => import("./../../../src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-abuse-how-we-handle-js": () => import("./../../../src/pages/legal/abuse/how-we-handle.js" /* webpackChunkName: "component---src-pages-legal-abuse-how-we-handle-js" */),
  "component---src-pages-legal-abuse-index-js": () => import("./../../../src/pages/legal/abuse/index.js" /* webpackChunkName: "component---src-pages-legal-abuse-index-js" */),
  "component---src-pages-legal-cancellation-js": () => import("./../../../src/pages/legal/cancellation.js" /* webpackChunkName: "component---src-pages-legal-cancellation-js" */),
  "component---src-pages-legal-copyright-js": () => import("./../../../src/pages/legal/copyright.js" /* webpackChunkName: "component---src-pages-legal-copyright-js" */),
  "component---src-pages-legal-incapacitated-js": () => import("./../../../src/pages/legal/incapacitated.js" /* webpackChunkName: "component---src-pages-legal-incapacitated-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-ownership-js": () => import("./../../../src/pages/legal/ownership.js" /* webpackChunkName: "component---src-pages-legal-ownership-js" */),
  "component---src-pages-legal-privacy-company-processors-js": () => import("./../../../src/pages/legal/privacy/company-processors.js" /* webpackChunkName: "component---src-pages-legal-privacy-company-processors-js" */),
  "component---src-pages-legal-privacy-index-js": () => import("./../../../src/pages/legal/privacy/index.js" /* webpackChunkName: "component---src-pages-legal-privacy-index-js" */),
  "component---src-pages-legal-privacy-readyfive-subprocessors-js": () => import("./../../../src/pages/legal/privacy/readyfive-subprocessors.js" /* webpackChunkName: "component---src-pages-legal-privacy-readyfive-subprocessors-js" */),
  "component---src-pages-legal-privacy-regulations-js": () => import("./../../../src/pages/legal/privacy/regulations.js" /* webpackChunkName: "component---src-pages-legal-privacy-regulations-js" */),
  "component---src-pages-legal-refund-js": () => import("./../../../src/pages/legal/refund.js" /* webpackChunkName: "component---src-pages-legal-refund-js" */),
  "component---src-pages-legal-security-index-js": () => import("./../../../src/pages/legal/security/index.js" /* webpackChunkName: "component---src-pages-legal-security-index-js" */),
  "component---src-pages-legal-security-response-js": () => import("./../../../src/pages/legal/security/response.js" /* webpackChunkName: "component---src-pages-legal-security-response-js" */),
  "component---src-pages-legal-sla-js": () => import("./../../../src/pages/legal/sla.js" /* webpackChunkName: "component---src-pages-legal-sla-js" */),
  "component---src-pages-legal-taxes-js": () => import("./../../../src/pages/legal/taxes.js" /* webpackChunkName: "component---src-pages-legal-taxes-js" */),
  "component---src-pages-legal-terms-js": () => import("./../../../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-mobile-js": () => import("./../../../src/pages/mobile.js" /* webpackChunkName: "component---src-pages-mobile-js" */),
  "component---src-pages-never-miss-a-page-index-js": () => import("./../../../src/pages/never-miss-a-page/index.js" /* webpackChunkName: "component---src-pages-never-miss-a-page-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

